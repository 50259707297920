












































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { Accountant } from '@/models';
import { AdminService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';

@Component
export default class AccountantList extends Vue {
  @Prop({ required: true })
  accountants: Accountant[];

  @Prop({ type: Array, default: () => ['name', 'companies', 'status'] })
  columns: string[];

  columnDefinitions = {
    id: { width: 1 },
    name: { width: 6 },
    companies: { width: 2 },
    status: { width: 2 },
  };

  async login(accountantId: number): Promise<void> {
    try {
      await AdminService.login(accountantId, 'accountant');
      await this.$router.push('/');
    } catch (e) {
      this.$toaster.error(unwrapError(e));
    }
  }
}
